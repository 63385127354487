<template>
  <div>
    <v-toolbar tile flat elevation="1">
      <v-btn @click="$router.go(-1)" class="no-active" icon>
        <v-icon>$vuetify.icons.arrow_left</v-icon>
      </v-btn>

      <v-toolbar-title>{{ postTitle }}</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-container v-if="!loadPage">
      <no-content v-if="!post" route-name title="Записи нет"></no-content>
      <v-row v-else>
        <v-col class="text-left" :cols="$vuetify.breakpoint.smAndUp ? 12 : ''">
          <div class="page-content" v-html="post.body"></div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
// import { bus } from "../mixins/bus.js";
import { mapGetters } from "vuex";
export default {
  components: {},
  data: () => ({
    postSlug: "",
  }),
  methods: {
    getPost() {
      let app = this;
      this.$store
        .dispatch("info/getPost", {
          slug: app.postSlug,
        })
        .then(function (responce) {
          if (responce.data.success == 1) {
            app.closeHello();
          }
        });
    },
    closeHello() {
      this.$store.commit("SET_CLOSE_HELLO");
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      loadPage: "loadPage",
      post: "info/post",
    }),
    postTitle() {
      if (this.post) {
        return this.post.title;
      }
      return "";
    },
  },
  mounted() {
    this.postSlug = this.$route.params.slug ? this.$route.params.slug : "";
    this.getPost();
  },
};
</script>
